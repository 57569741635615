import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default class EmailListForm extends React.Component {
  
  constructor() {
    super()
    this.state = { email: "", result: { msg: "", result: ""} }
  }

  _handleSubmit = async e => {
    e.preventDefault();

    const result = await addToMailchimp(this.state.email);
    this.setState({result: result});
    console.log(this.state.result.result);
  }

  _handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  render () {
    return (
      <form onSubmit={this._handleSubmit} className='EmailListForm'>
        <h2>Subscribe to Context-First</h2>
        <p>Get the latest news delivered right to your inbox</p>
        <div className="form-group">
          <input
            placeholder="Email address"
            name="email"
            type="text"
            onChange={this._handleEmailChange}
          />
          <button type="submit">Subscribe</button>
        </div>
        <div className="result" dangerouslySetInnerHTML={{ __html: this.state.result.msg }} />
      </form>
    );
  }
}
